var templates={};
templates.default={};

templates.awards='<a href="/news/{{uri}}" class="card"><div class="card__image" style="background-image: url({{#image}}/uploads/news/800x400/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"><div class="block-overlay"><span>Дізнатися більше</span> <svg><use xlink:href="#linkArrow"/></svg></div></div><div class="card__title-group"><span class="card__title">{{title}}</span><div class="news__info"><span class="news__date">{{published}}</span> <svg><use xlink:href="#eye"/></svg> <span class="news__views">{{views_count}}</span></div></div></a>';

templates.books='<div class="card" target="_blank">{{#file}}<div class="card__image" style="background-image: url({{#image}}/uploads/books/200x270/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"><a href="/uploads/files/{{file}}" class="block-overlay" target="_blank"><span>Завантажити</span> <svg><use xlink:href="#download"/></svg></a></div>{{/file}} {{^file}}<div class="card__image" style="background-image: url({{#image}}/uploads/books/200x270/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"></div>{{/file}} <span class="card__author">{{author}}</span> <span class="card__name">{{title}}</span></div>';

templates.developments='<a href="/news/{{uri}}" class="card"><div class="card__image" style="background-image: url({{#image}}/uploads/news/800x400/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"><div class="block-overlay"><span>Дізнатися більше</span> <svg><use xlink:href="#linkArrow"/></svg></div></div><div class="card__title-group"><span class="card__title">{{title}}</span><div class="news__info"><span class="news__date">{{published}}</span> <svg><use xlink:href="#eye"/></svg> <span class="news__views">{{views_count}}</span></div></div></a>';

templates.filter='<div class="books__input-group" data-id="{{id}}" data-caption="{{key}}"><label class="form-check-label" for="{{key}}-{{id}}"><input class="form-check-input book-{{key}}" data-caption="{{key}}" type="checkbox" value="{{id}}" id="{{key}}-{{id}}"> <span>{{title}}</span></label></div>';

templates.media_images='<a class="media__photo" href="/uploads/media/original/{{title}}" data-fancybox="gallery"><img src="/uploads/media/original/{{title}}"></a>';

templates.media_list='<a href="/media/{{uri}}" class="media__item"><span class="media__title">{{title}}</span><div class="media__overlay"></div><img class="media__img" src="{{#image}}/uploads/media/original/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}}"></a>';

templates.media_videos='<div class="media__item media__video"><div class="media__overlay"></div><img class="media__img" src="/uploads/media/videos/{{preview}}"> <button class="play-btn" data-video="{{title}}"><svg><use xlink:href="#play"/></svg></button> <span>{{media_caption}}</span></div>';

templates.news='<a href="/news/{{uri}}" class="card"><div class="card__image" style="background-image: url({{#image}}/uploads/news/800x400/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"><div class="block-overlay"><span>Дізнатися більше</span> <svg><use xlink:href="#linkArrow"/></svg></div></div><div class="card__title-group"><span class="card__title">{{title}}</span><div class="news__info"><span class="news__date">{{published}}</span> <svg><use xlink:href="#eye"/></svg> <span class="news__views">{{views_count}}</span></div></div></a>';

templates.projects='<a href="/projects/{{uri}}" class="awards__item" style="background-image: url({{#image}}/uploads/news/800x400/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"><span class="awards__title">{{title}}</span><div class="awards__overlay"></div></a>';

templates.success='<a href="/success/{{uri}}" class="card"><div class="card__image" style="background-image: url({{#image}}/uploads/news/800x400/{{image}}{{/image}}{{^image}}/images/placeholder.jpg{{/image}});"><div class="block-overlay"><span>Дізнатися більше</span> <svg><use xlink:href="#linkArrow"/></svg></div></div><div class="card__title-group"><span class="card__title">{{title}}</span><div class="news__info"><span class="news__date">{{published}}</span> <svg><use xlink:href="#eye"/></svg> <span class="news__views">{{views_count}}</span></div></div></a>';

templates.table_cell='<div class="table__cell-content"><div class="table__cell-body"><span class="table__subject">{{#subject}}{{subject}}{{/subject}}</span> <span class="table__teacher">{{#teacher}}{{teacher}}{{/teacher}}</span></div><div class="table__cell-footer"><span class="table__class">{{#room}}{{room}}{{/room}}</span></div></div>';

templates.table_head='<tr data-number="{{number}}"><th>{{number}}</th><td data-day="monday"></td><td data-day="tuesday"></td><td data-day="wednesday"></td><td data-day="thursday"></td><td data-day="friday"></td><td data-day="saturday"></td></tr>';

templates.vacancies='<div class="vacancies__card"><div class="vacancies__header"><h2 class="vacancies__title">{{name}}</h2>{{#position}}<span class="vacancies__badge">{{position}}</span>{{/position}}</div><span class="vacancies__req">Вимоги:</span><ul class="profession__why-list"><li class="profession__why-item">широке коло знайомств</li><li class="profession__why-item">придбання навичок спілкування з різним контингентом населення</li><li class="profession__why-item">досвід організаторської діяльності</li><li class="profession__why-item">робота стабільна і не припускає великого фізичного навантаження</li></ul></div>';

templates.video='<iframe width="{{width}}" height="{{height}}" src="https://www.youtube.com/embed/{{video_id}}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';